import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-338baa6e"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "redeem-coupon-page-root main-scroll-topbar-content"
};
const _hoisted_2 = {
  class: "input-box"
};
const _hoisted_3 = {
  class: "common-btn-box"
};
import TopNavigator from "../components/TopNavigator.vue";
import { ref, watch } from "vue";
import CommonBtn from "../components/CommonBtn.vue";
import AV from "../assets/utils/av";
import { jsonify, getServerTime, date2ISO } from "../assets/utils/common.utils";
import request from "../assets/utils/request";
import { nftShopApi } from "../common.config";
import { Toast } from "vant";
import dayjs from "dayjs";
import router from "../router";
export default {
  __name: 'RedeemCoupon',
  props: {},

  setup(__props) {
    const props = __props; // 兑换码值

    const redeemCode = ref(""); // 兑换码ID

    const discountCodeId = ref(""); // 兑换码兑换验证提示  有效/无此兑换码

    const verificationInfo = ref(""); // 监听兑换码兑换

    watch(redeemCode, (newVal, oldVal) => {
      if (newVal) {
        const discountCodeQuery = new AV.Query("RedeemCode");
        const regStr = new RegExp(`^${redeemCode.value}$`, "i");
        discountCodeQuery.matches("code", regStr);
        discountCodeQuery.first().then(res => {
          if (res) {
            const discountCodeRes = jsonify(res);
            getServerTime().then(sTime => {
              const st = date2ISO(sTime);

              if (discountCodeRes.isValid && dayjs(discountCodeRes === null || discountCodeRes === void 0 ? void 0 : discountCodeRes.expiresAt) >= dayjs(st)) {
                discountCodeId.value = discountCodeRes.objectId;
                verificationInfo.value = "";
              } else if (dayjs(discountCodeRes === null || discountCodeRes === void 0 ? void 0 : discountCodeRes.expiresAt) < dayjs(st)) {
                verificationInfo.value = "兑换码过期";
                discountCodeId.value = "";
              } else {
                verificationInfo.value = "兑换码无效";
                discountCodeId.value = "";
              }
            }).catch(err => {
              console.error("获取服务器时间 err: ", err);
            });
          } else {
            verificationInfo.value = "请输入正确的兑换码";
            discountCodeId.value = "";
          }
        }).catch(err => {
          verificationInfo.value = "兑换码查询出错";
          discountCodeId.value = "";
          console.error("兑换码查询: ", err);
        });
      } else {
        verificationInfo.value = "";
        discountCodeId.value = "";
      }
    });
    const redeemLoading = ref(false);

    const handleRedeem = () => {
      if (!verificationInfo.value && discountCodeId.value) {
        redeemLoading.value = true;
        Toast.loading({
          message: "",
          duration: 0,
          forbidClick: true
        });
        request.post(`${nftShopApi}/code/redeem_ticket`, {
          code_id: discountCodeId.value
        }, {
          headers: {
            if2023Timestamp: parseInt(new Date().getTime() / 1000)
          }
        }).then(res => {
          if (res.code == 200) {
            router.push(`/add-grounp-buy?orderId=${res.data.objectId}&fromRedeem=T`);
            Toast.clear();
          } else {
            Toast.fail("兑换失败");
          }

          redeemLoading.value = false;
        }).catch(err => {
          redeemLoading.value = false;
          Toast.fail("兑换失败");
          console.error("兑换码兑换：", err);
        });
      } else if (!verificationInfo.value && !discountCodeId.value) {
        Toast.fail("输入兑换码");
      } else {
        Toast.fail(verificationInfo.value);
      }
    };

    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");

      const _component_van_cell_group = _resolveComponent("van-cell-group");

      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(TopNavigator, {
        title: "兑换"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_cell_group, null, {
        default: _withCtx(() => [_createVNode(_component_van_field, {
          modelValue: redeemCode.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => redeemCode.value = $event),
          placeholder: "请输入兑换码",
          type: "text",
          "error-message": verificationInfo.value
        }, null, 8, ["modelValue", "error-message"])]),
        _: 1
      })]), _createElementVNode("div", _hoisted_3, [_createVNode(CommonBtn, {
        text: "兑 换",
        width: "100%",
        height: "32px",
        loading: redeemLoading.value,
        onHandleClick: handleRedeem
      }, null, 8, ["loading"])])])], 64);
    };
  }

};